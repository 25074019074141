import { message } from 'antd'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { paths } from '../../config'
import {
  createVillageAPI,
  deleteVillageAPI,
  getAllSubdistrictAPI,
  getAllVillageAPI,
  updateVillageAPI,
} from './villageAPI'

const useSelectSubdistrict = () => {
  const [subdistrict, setsubdistrict] = useState({ data: null, loading: false })

  const getList = async () => {
    try {
      setsubdistrict({ loading: true, ...subdistrict })
      const { data } = await getAllSubdistrictAPI()
      setsubdistrict({ loading: true, data })
    } catch (error) {
      message.error(error.message)
    }
  }

  useEffect(() => {
    getList()
  }, [])

  return { subdistrict }
}

const useVillage = () => {
  const [village, setVillage] = useState({ data: null, loading: false })
  const [refresh, setRefresh] = useState(false)

  const getList = async () => {
    try {
      setVillage({ ...village, loading: true })
      const { data } = await getAllVillageAPI()

      setVillage({ loading: false, data })
    } catch (error) {
      message.error(error.message)
    }
  }

  const refreshData = () => {
    setRefresh(!refresh)
  }

  useEffect(() => {
    getList()
    setRefresh(refresh)
  }, [refresh])

  return { village, refreshData, getList, setVillage, setRefresh }
}

const useStoredVillage = () => {
  const navigate = useNavigate()

  const createVillage = async (payload) => {
    try {
      await createVillageAPI(payload)
      message.info('Create village success')
      navigate(`/${paths.masterVillage}`)
    } catch (error) {
      message.error(error.message)
    }
  }

  const updateVillage = async (payload, id) => {
    try {
      await updateVillageAPI(payload, id)
      message.info('Update village success')
      navigate(`/${paths.masterVillage}`)
    } catch (error) {
      message.error(error.message)
    }
  }

  const deleteVillage = async (id) => {
    try {
      await deleteVillageAPI(id)
      message.info('Delete Success')
    } catch (error) {
      message.error(error.message)
    }
  }

  return { updateVillage, deleteVillage, createVillage }
}

export { useVillage, useStoredVillage, useSelectSubdistrict }
