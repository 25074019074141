import { useSelector } from 'react-redux'
import { Navigate } from 'react-router-dom'

import { GLOBAL_CONST } from '../../constant'
import { getToken } from '../cookies'

import { LayoutPage } from '../../components'

function ProtectedRoutes({ children }) {
  const { isAuthenticated } = useSelector((state) => state[GLOBAL_CONST.AUTH_KEY_REDUX])

  if (!isAuthenticated && !getToken()) {
    return <Navigate replace to={'/login'} />
  }
  return <LayoutPage children={children} />
}

export default ProtectedRoutes
