import { message } from 'antd'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { paths } from '../../config'
import { createCityAPI, deleteCityAPI, getAllCityAPI, updateCityAPI, getAllProvinceAPI } from './cityAPI'

const useSelectProvince = () => {
  const [province, setProvince] = useState({ data: null, loading: false })

  const getList = async () => {
    try {
      setProvince({ loading: true, ...province })
      const { data } = await getAllProvinceAPI()
      setProvince({ loading: true, data })
    } catch (error) {
      message.error(error.message)
    }
  }

  useEffect(() => {
    getList()
  }, [])

  return { province }
}

const useCity = () => {
  const [city, setCity] = useState({ data: null, loading: false })
  const [refresh, setRefresh] = useState(false)

  const getList = async () => {
    try {
      setCity({ ...city, loading: true })
      const { data } = await getAllCityAPI()

      setCity({ loading: false, data })
    } catch (error) {
      message.error(error.message)
    }
  }

  const refreshData = () => {
    setRefresh(!refresh)
  }

  useEffect(() => {
    getList()
    setRefresh(refresh)
  }, [refresh])

  return { city, refreshData, getList, setCity, setRefresh }
}

const useStoredCity = () => {
  const navigate = useNavigate()

  const createCity = async (payload) => {
    try {
      await createCityAPI(payload)
      message.info('Create city success')
      navigate(`/${paths.masterCity}`)
    } catch (error) {
      message.error(error.message)
    }
  }

  const updateCity = async (payload, id) => {
    try {
      await updateCityAPI(payload, id)
      message.info('Update city success')
      navigate(`/${paths.masterCity}`)
    } catch (error) {
      message.error(error.message)
    }
  }

  const deleteCity = async (id) => {
    try {
      await deleteCityAPI(id)
      message.info('Delete Success')
    } catch (error) {
      message.error(error.message)
    }
  }

  return { createCity, updateCity, deleteCity }
}

export { useCity, useStoredCity, useSelectProvince }
