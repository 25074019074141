import { message } from 'antd'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { paths } from '../../config'
import { createProvinceAPI, deleteProvinceAPI, getAllProvinceAPI, updateProvinceAPI } from './provinceAPI'

const useProvince = () => {
  const [province, setProvince] = useState({ data: null, loading: false })
  const [refresh, setRefresh] = useState(false)

  const getList = async () => {
    try {
      setProvince({ ...province, loading: true })
      const { data } = await getAllProvinceAPI()
      setProvince({ loading: false, data })
    } catch (error) {
      message.error(error.message)
    }
  }

  const refreshData = () => {
    setRefresh(!refresh)
  }

  useEffect(() => {
    getList()
    setRefresh(refresh)
  }, [refresh])

  return { province, refreshData, getList, setProvince, setRefresh }
}

const useStoredProvince = () => {
  const navigate = useNavigate()

  const createProvince = async (payload) => {
    try {
      await createProvinceAPI(payload)
      message.info('Create province success')
      navigate(`/${paths.masterProvince}`)
    } catch (error) {
      message.error(error.message)
    }
  }

  const updateProvince = async (payload, id) => {
    try {
      await updateProvinceAPI(payload, id)
      message.info('Update province success')
      navigate(`/${paths.masterProvince}`)
    } catch (error) {
      message.error(error.message)
    }
  }

  const deleteProvince = async (id) => {
    try {
      await deleteProvinceAPI(id)
      message.info('Delete Success')
    } catch (error) {
      message.error(error.message)
    }
  }

  return { createProvince, updateProvince, deleteProvince }
}

export { useProvince, useStoredProvince }
