export { default as endpoints } from './endpoints'
export { default as request } from './request'
export * as imageLib from './image'

const validateNumber = (rule, value) => {
  return new Promise((resolve, reject) => {
    if (isNaN(value)) {
      reject('Please enter a valid number')
    } else {
      resolve()
    }
  })
}

export { validateNumber }
