import { configureStore } from '@reduxjs/toolkit'
import { GLOBAL_CONST } from '../constant'

import loginReducer from '../pages/Login/login.slice'

export default configureStore({
  reducer: {
    [GLOBAL_CONST.AUTH_KEY_REDUX]: loginReducer,
  },
})
