import { useSelector } from 'react-redux'
import { useLocation, Navigate } from 'react-router-dom'
import { GLOBAL_CONST } from '../../constant'

const PublicRoutes = ({ children }) => {
  const location = useLocation()
  const { isAuthenticated } = useSelector((state) => state[GLOBAL_CONST.AUTH_KEY_REDUX])

  if (isAuthenticated && ['/', '/login'].includes(location.pathname)) {
    let redirectTo = null
    if (!redirectTo) {
      redirectTo = 'dashboard'
    }
    return <Navigate replace to={`/${redirectTo}`} />
  }
  return <>{children}</>
}

export default PublicRoutes
