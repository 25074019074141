const endpoints = {
  dashboard: '/dashboard',
  authenticate: 'user/authenticate',
  login: '/user/signin',
  role: '/roles',
  masterCandidate: '/candidate',
  masterCity: '/city',
  masterEmoney: '/emoney',
  masterProvince: '/province',
  masterVillage: '/village',
  masterSubdistrict: '/subdistrict',
  masterUser: '/user',
  voter: '/voter',
  scanner: '/scanner',
  scanVoter: '/scan',
}

export default endpoints
