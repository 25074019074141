import axios from 'axios'
import { message } from 'antd'
import { cookies } from '../config'

// const API_URL = 'https://localhost'
// const API_URL = 'https://emc-backend.powerdigital.id'
const API_URL = 'https://34.170.24.3/'

const request = axios
request.defaults.baseURL = API_URL
request.defaults.withCredentials = true
request.interceptors.request.use(
  (config) => {
    if (!config.headers.Authorization) {
      const token = cookies.getToken()
      if (token) {
        config.headers['Authorization'] = `Bearer ${token}`
      }
    }
    return config
  },
  (err) => Promise.reject(err),
)

request.interceptors.response.use(
  (response) => response.data,
  (err) => {
    message.error(`${err?.response?.status} ${err?.response?.data?.message}`)
    if (err?.response?.status === 401) {
      cookies.removeToken()
      // useNavigate()("/login");
    }
    return Promise.reject(err)
  },
)

export default request
