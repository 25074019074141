import { endpoints, request } from '../../utils'

export function getAllSubdistrictAPI() {
  return request.get(`${endpoints.masterSubdistrict}`)
}

export function getAllVillageAPI() {
  return request.get(`${endpoints.masterVillage}`)
}

export function createVillageAPI(payload) {
  return request.post(`${endpoints.masterVillage}`, payload)
}

export function updateVillageAPI(payload, id) {
  return request.put(`${endpoints.masterVillage}/${id}`, payload)
}

export function deleteVillageAPI(id) {
  return request.delete(`${endpoints.masterVillage}/${id}`)
}
