import { endpoints, request } from '../../utils'

export function getAllProvinceAPI() {
  return request.get(`${endpoints.masterProvince}`)
}

export function createProvinceAPI(payload) {
  return request.post(`${endpoints.masterProvince}`, payload)
}

export function updateProvinceAPI(payload, id) {
  return request.put(`${endpoints.masterProvince}/${id}`, payload)
}

export function deleteProvinceAPI(id) {
  return request.delete(`${endpoints.masterProvince}/${id}`)
}
