export async function getDataUriFromFile(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.addEventListener('load', () => resolve(reader.result))
    reader.readAsDataURL(file)
  })
}

export function checkValidDataUri(dataUri) {
  const str = dataUri.replace('data:', '').replace(/^.+,/, '')
  return str.length % 4 == 0 && /^[A-Za-z0-9+/]+[=]{0,2}$/.test(str)
}
