import { Select } from 'antd'

const SearchSelect = (props) => {
  const { onSelect, option, intialValues, fieldNames, mode, allowClear, onChange } = props

  return (
    <Select
      showSearch
      allowClear={allowClear}
      onChange={onChange}
      mode={mode}
      fieldNames={fieldNames}
      value={intialValues}
      optionFilterProp="children"
      onSelect={onSelect}
      filterOption={(input, option) => (option?.name ?? '').toLowerCase().includes(input.toLowerCase())}
      options={option}
    />
  )
}
export default SearchSelect
