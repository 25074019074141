const paths = {
  dashboard: 'dashboard',
  scanner: 'scanner',
  login: '/login',
  masterCandidate: 'candidate',
  masterCandidateAction: 'candidate/:action',
  masterCity: 'city',
  masterCityAction: 'city/:action',
  masterEmoney: 'emoney',
  masterEmoneyAction: 'emoney/:action',
  masterProvince: 'province',
  masterProvinceAction: 'province/:action',
  masterVillage: 'village',
  masterVillageAction: 'village/:action',
  masterSubdistrict: 'subdistrict',
  masterSubdistrictAction: 'subdistrict/:action',
  masterUser: 'user',
  masterUserAction: 'user/:action',
  voter: 'voter',
  voterAction: 'voter/:action',
}

export default paths
