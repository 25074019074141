import { message } from 'antd'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { paths } from '../../config'
import {
  createSubdistrictAPI,
  deleteSubdistrictAPI,
  getAllCityAPI,
  getAllSubdistrictAPI,
  updateSubdistrictAPI,
} from './subdistrictAPI'

const useSelectCity = () => {
  const [city, setCity] = useState({ data: null, loading: false })

  const getList = async () => {
    try {
      setCity({ loading: true, ...city })
      const { data } = await getAllCityAPI()
      setCity({ loading: true, data })
    } catch (error) {
      message.error(error.message)
    }
  }

  useEffect(() => {
    getList()
  }, [])

  return { city }
}

const useSubdistrict = () => {
  const [subdistrict, setSubdistrict] = useState({ data: null, loading: false })
  const [refresh, setRefresh] = useState(false)

  const getList = async () => {
    try {
      setSubdistrict({ ...subdistrict, loading: true })
      const { data } = await getAllSubdistrictAPI()

      setSubdistrict({ loading: false, data })
    } catch (error) {
      message.error(error.message)
    }
  }

  const refreshData = () => {
    setRefresh(!refresh)
  }

  useEffect(() => {
    getList()
    setRefresh(refresh)
  }, [refresh])

  return { subdistrict, refreshData, getList, setSubdistrict, setRefresh }
}

const useStoredSubdistrict = () => {
  const navigate = useNavigate()

  const createSubdistrict = async (payload) => {
    try {
      await createSubdistrictAPI(payload)
      message.info('Create subdistrict success')
      navigate(`/${paths.masterSubdistrict}`)
    } catch (error) {
      message.error(error.message)
    }
  }

  const updateSubdistrict = async (payload, id) => {
    try {
      await updateSubdistrictAPI(payload, id)
      message.info('Update subdistrict success')
      navigate(`/${paths.masterSubdistrict}`)
    } catch (error) {
      message.error(error.message)
    }
  }

  const deleteSubdistrict = async (id) => {
    try {
      await deleteSubdistrictAPI(id)
      message.info('Delete Success')
    } catch (error) {
      message.error(error.message)
    }
  }

  return { updateSubdistrict, createSubdistrict, deleteSubdistrict }
}

export { useSubdistrict, useStoredSubdistrict, useSelectCity }
