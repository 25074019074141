import { createSlice } from '@reduxjs/toolkit'
import { message } from 'antd'

import { cookies } from '../../config'
import { GLOBAL_CONST } from '../../constant'
import { getProfileAPI, signInAPI } from './login.api'

const loginSlice = createSlice({
  name: GLOBAL_CONST.AUTH_KEY_REDUX,
  initialState: {
    isAuthenticated: !!cookies.getToken(),
    data: null,
    userProfile: localStorage.getItem('userProfile') ? JSON.parse(localStorage.getItem('userProfile')) : null,
    error: null,
    validateUserLoader: false,
  },
  reducers: {
    userInfoActionReducer: (state) => {
      return { ...state, validateUserLoader: true }
    },
    userInfoReducer: (state, action) => {
      return { ...state, userProfile: action.payload, validateUserLoader: false, isAuthenticated: true, error: null }
    },
    loginSuccess: (state, action) => {
      state.isAuthenticated = true
      state.data = action.payload
      state.error = null
    },
    errorReducer: (state, action) => {
      state.isAuthenticated = false
      state.data = null
      state.error = action.payload
    },
    logoutSuccess: (state) => {
      state.isAuthenticated = false
      state.data = null
      state.error = null
    },
  },
})

const { loginSuccess, errorReducer, logoutSuccess, userInfoActionReducer, userInfoReducer } = loginSlice.actions

export const userInfo = (state) => state[GLOBAL_CONST.AUTH_KEY_REDUX]
export const postSignIn = (payload) => async (dispatch) => {
  try {
    const { data } = await signInAPI(payload)
    dispatch(loginSuccess(data))
    localStorage.setItem('rl', JSON.stringify({ data: data }))
    cookies.setToken(data.token, 0.5)
    message.success('success login')
  } catch (error) {
    dispatch(errorReducer(error))
  }
}

export const getUserProfile = () => async (dispatch) => {
  try {
    dispatch(userInfoActionReducer())
    const { data } = await getProfileAPI()
    dispatch(userInfoReducer(data?.payload))
  } catch (error) {
    dispatch(errorReducer(error))
  }
}

export const signOut = () => (dispatch) => {
  cookies.removeToken()
  localStorage.removeItem('rl')
  dispatch(logoutSuccess())
  message.info('sign out success', 10)
}

export default loginSlice.reducer
