import { endpoints, request } from '../../utils'

export function getAllCityAPI() {
  return request.get(`${endpoints.masterCity}`)
}

export function getAllSubdistrictAPI() {
  return request.get(`${endpoints.masterSubdistrict}`)
}

export function createSubdistrictAPI(payload) {
  return request.post(`${endpoints.masterSubdistrict}`, payload)
}

export function updateSubdistrictAPI(payload, id) {
  return request.put(`${endpoints.masterSubdistrict}/${id}`, payload)
}

export function deleteSubdistrictAPI(id) {
  return request.delete(`${endpoints.masterSubdistrict}/${id}`)
}
