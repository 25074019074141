import React, { useState, useEffect } from 'react'
import { Col, Input, Table, Button, Space, Dropdown, Select } from 'antd'
import { SearchOutlined } from '@ant-design/icons'
import { SyncOutlined } from '@ant-design/icons'
// import { Heading } from '..'
// import { useVoter } from '../../pages/Voter/useVoter'
import { useProvince } from '../../pages/MasterProvince/useProvince'
import { useCity } from '../../pages/MasterCity/useCity'
import { useSubdistrict } from '../../pages/MasterSubdistrict/useSubdistrict'
import { useVillage } from '../../pages/MasterVillage/useVillage'

export function TableSearch(props) {
  const { dataSource } = props
  const [searchText, setSearchText] = useState('')
  const {province, setProvince} = useProvince()
  const { city } = useCity()
  const { subdistrict } = useSubdistrict()
  const { village } = useVillage()


  const dataprov = {...province.data?.province}
  let dataKeys = Object.keys(dataprov)
  const listProvince = dataKeys.map((key) => { 
    let item = dataprov[key]

    return {value: item.name, label: item.name}
  })

    const [filteredData, setFilteredData] = useState();
    const [selectedProvince, setSelectedProvince] = useState("Select Province");
    const [selectedCity, setSelectedCity] = useState("Select City");
    const [selectedSubdistrict, setSelectedSubdistrict] = useState("Select Subdistrict");
    const [selectedVillage, setSelectedVillage] = useState("Select Village")

    const dataCity = { ...city.data?.city }
    let cityKeys = Object.keys(dataCity)
    const listCity = cityKeys
      .filter((key) => dataCity[key].province.name === selectedProvince)
      .map((key) => {
        let item = dataCity[key]
        return { value: item.name, label: item.name }
      })

    const dataSubdistrict = { ...subdistrict.data?.subdistrict }
    let subdistrictKeys = Object.keys(dataSubdistrict)
    const listSubdistrict = subdistrictKeys
      .filter((key) => dataSubdistrict[key].city.name === selectedCity)
      .map((key) => {
        let item = dataSubdistrict[key]
        return { value: item.name, label: item.name }
      })

      const dataVillage = { ...village.data?.village }
      let villageKeys = Object.keys(dataVillage)
      const listVillage = villageKeys
        .filter((key) => dataVillage[key].subdistrict.name === selectedSubdistrict)
        .map((key) => {
          let item = dataVillage[key]
          return { value: item.name, label: item.name }
        })

    const handleProvinceFilter = (value) => {
      setSelectedProvince(value);
      if (value === "Select Province") {
        setFilteredData(dataSource);
      } else {
        const filtered = dataSource.filter((item) => item.province.name === value);
        setFilteredData(filtered);
      }
  }

  const handleCityFilter = (value) => {
    setSelectedCity(value);
    if (value === "Select City") {
      setFilteredData(dataSource);
    } else {
      const filtered = dataSource.filter((item) => item.province.name === selectedProvince && item.city.name === value);
      setFilteredData(filtered);
    }
  }

  const handleSubdistrictFilter = (value) => {
    setSelectedSubdistrict(value);
    if (value === "Select Subdistrict") {
      setFilteredData(dataSource);
    } else {
      const filtered = dataSource.filter((item) => item.province.name === selectedProvince && item.city.name === selectedCity && item.subdistrict.name === value);
      setFilteredData(filtered);
    }
  }

  const handleVillageFilter = (value) => {
    setSelectedVillage(value);
    if (value === "Select Village") {
      setFilteredData(dataSource);
    } else {
      const filtered = dataSource.filter((item) => item.province.name === selectedProvince && item.city.name === selectedCity && item.subdistrict.name === selectedSubdistrict && item.village.name === value);
      setFilteredData(filtered);
    }
  }
  
  const handleSearch = (e) => {
    setSearchText(e.target.value);
    const filteredData = dataSource.filter((item) => {
      // Replace 'fieldName' with the actual field name in your dataSource
      const filedValue = item.name.toLowerCase() || item.nik.toLowerCase();
      return filedValue.includes(searchText.toLowerCase());
    });
    setFilteredData(filteredData)
  };



  useEffect (() => {
    setFilteredData(dataSource);
  }, [dataSource]);

  return (
    <Col span={24} className="table-display-wrapper">
      <Col>
        <Input.Search onChange={handleSearch} allowClear style={{ marginBottom: 20 }} enterButton={<SearchOutlined />} />
        <Space style={{ marginBottom: 20 }} wrap size={[2, 2]}>
          <Select
            value={selectedProvince}
            defaultValue="Select Province"
            style={{ width: 120 }}
            dropdownMatchSelectWidth={false}
            onChange={handleProvinceFilter}
          >
          <Select.Option value={"Select Province"}>Select Province</Select.Option>
            {
              listProvince.map((item, idx) => {
                return <Select.Option key={idx} value={item.value}>{item.label}</Select.Option>
              })
            }
          </Select>
          <Select
            value={selectedCity}
            defaultValue="Select City"
            style={{ width: 120 }}
            dropdownMatchSelectWidth={false}
            onChange={handleCityFilter}
          >
          <Select.Option value={"Select City"}>Select City</Select.Option>
            {
              listCity.map((item, idx) => {
                return <Select.Option key={idx} value={item.value}>{item.label}</Select.Option>
              })
            }
          </Select>
          <Select
            value={selectedSubdistrict}
            defaultValue="Select Subdistrict"
            style={{ width: 120 }}
            dropdownMatchSelectWidth={false}
            onChange={handleSubdistrictFilter}
          >
          <Select.Option value={"Select Subdistrict"}>Select Subdistrict</Select.Option>
            {
              listSubdistrict.map((item, idx) => {
                return <Select.Option key={idx} value={item.value}>{item.label}</Select.Option>
              })
            }
          </Select>
          <Select
            value={selectedVillage}
            defaultValue="Select Village"
            style={{ width: 120 }}
            dropdownMatchSelectWidth={false}
            onChange={handleVillageFilter}
          >
          <Select.Option value={"Select Village"}>Select Village</Select.Option>
            {
              listVillage.map((item, idx) => {
                return <Select.Option key={idx} value={item.value}>{item.label}</Select.Option>
              })
            }
          </Select>

        </Space>
      </Col>
      <Table
        rowKey="id"
        columns={props.columns}
        dataSource={filteredData}
        scroll={{
          x: 1500,
          y: 300,
        }}
      />
    </Col>
  )
}

export function TableDisplay(props) {
  const { onClick, dataSource, columns, loading, refreshData } = props

  return (
    <Col span={24} className="table-display-wrapper">
      <Table
        rowKey="id"
        columns={columns}
        dataSource={dataSource}
        loading={loading}
        title={() => (
          <>
            <Space wrap>
              <Space.Compact block>
                <Button width={50} icon={<SyncOutlined />} type="default" onClick={refreshData}>
                  Refresh
                </Button>
                <Button width={50} type="primary" onClick={onClick}>
                  Tambah Data
                </Button>
              </Space.Compact>
            </Space>
          </>
        )}
        scroll={{
          x: 'max-content',
          y: 250,
        }}
      />
    </Col>
  )
}
