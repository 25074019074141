import { lazy } from 'react'
import paths from './paths'
import PublicRoutes from './PublicRoutes'
import ProtectedRoutes from './ProtectedRoutes'

const Dashboard = lazy(() => import('../../pages/Dashboard'))
const Login = lazy(() => import('../../pages/Login'))
const Voter = lazy(() => import('../../pages/Voter'))
const Scanner = lazy(() => import('../../pages/Scanner'))
const MasterCandidate = lazy(() => import('../../pages/MasterCandidate'))
const MasterCandidateAction = lazy(() => import('../../pages/MasterCandidate/CandidateCreate'))
const MasterCity = lazy(() => import('../../pages/MasterCity'))
const MasterCityAction = lazy(() => import('../../pages/MasterCity/CreateCity'))
const MasterEmoney = lazy(() => import('../../pages/MasterEmoney'))
const MasterEmoneyAction = lazy(() => import('../../pages/MasterEmoney/CreateEmoney'))
const MasterProvince = lazy(() => import('../../pages/MasterProvince'))
const MasterProvinceAction = lazy(() => import('../../pages/MasterProvince/CreateProvince'))
const MasterSubdistrict = lazy(() => import('../../pages/MasterSubdistrict'))
const MasterSubdistrictAction = lazy(() => import('../../pages/MasterSubdistrict/CreateSubdistrict'))
const MasterUser = lazy(() => import('../../pages/MasterUser'))
const MasterUserAction = lazy(() => import('../../pages/MasterUser/CreateUser'))
const MasterVillage = lazy(() => import('../../pages/MasterVillage'))
const MasterVillageAction = lazy(() => import('../../pages/MasterVillage/CreateVillage'))

const routes = [
  {
    path: paths.login,
    element: (
      <PublicRoutes>
        <Login />
      </PublicRoutes>
    ),
  },
  {
    path: paths.voter,
    element: (
      <ProtectedRoutes>
        <Voter />
      </ProtectedRoutes>
    ),
  },
  {
    path: paths.scanner,
    element: (
      <ProtectedRoutes>
        <Scanner />
      </ProtectedRoutes>
    ),
  },
  {
    path: paths.dashboard,
    element: (
      <ProtectedRoutes>
        <Dashboard />
      </ProtectedRoutes>
    ),
  },
  {
    path: paths.masterCandidate,
    element: (
      <ProtectedRoutes>
        <MasterCandidate />
      </ProtectedRoutes>
    ),
  },
  {
    path: paths.masterCandidateAction,
    element: (
      <ProtectedRoutes>
        <MasterCandidateAction />
      </ProtectedRoutes>
    ),
  },
  {
    path: paths.masterCity,
    element: (
      <ProtectedRoutes>
        <MasterCity />
      </ProtectedRoutes>
    ),
  },
  {
    path: paths.masterCityAction,
    element: (
      <ProtectedRoutes>
        <MasterCityAction />
      </ProtectedRoutes>
    ),
  },
  {
    path: paths.masterEmoney,
    element: (
      <ProtectedRoutes>
        <MasterEmoney />
      </ProtectedRoutes>
    ),
  },
  {
    path: paths.masterEmoneyAction,
    element: (
      <ProtectedRoutes>
        <MasterEmoneyAction />
      </ProtectedRoutes>
    ),
  },
  {
    path: paths.masterProvince,
    element: (
      <ProtectedRoutes>
        <MasterProvince />
      </ProtectedRoutes>
    ),
  },
  {
    path: paths.masterProvinceAction,
    element: (
      <ProtectedRoutes>
        <MasterProvinceAction />
      </ProtectedRoutes>
    ),
  },
  {
    path: paths.masterSubdistrict,
    element: (
      <ProtectedRoutes>
        <MasterSubdistrict />
      </ProtectedRoutes>
    ),
  },
  {
    path: paths.masterSubdistrictAction,
    element: (
      <ProtectedRoutes>
        <MasterSubdistrictAction />
      </ProtectedRoutes>
    ),
  },
  {
    path: paths.masterUser,
    element: (
      <ProtectedRoutes>
        <MasterUser />
      </ProtectedRoutes>
    ),
  },
  {
    path: paths.masterUserAction,
    element: (
      <ProtectedRoutes>
        <MasterUserAction />
      </ProtectedRoutes>
    ),
  },
  {
    path: paths.masterVillage,
    element: (
      <ProtectedRoutes>
        <MasterVillage />
      </ProtectedRoutes>
    ),
  },
  {
    path: paths.masterVillageAction,
    element: (
      <ProtectedRoutes>
        <MasterVillageAction />
      </ProtectedRoutes>
    ),
  },
]

export default routes
