import { endpoints, request } from '../../utils'

export function getAllProvinceAPI() {
  return request.get(`${endpoints.masterProvince}`)
}

export function getAllCityAPI() {
  return request.get(`${endpoints.masterCity}`)
}

export function createCityAPI(payload) {
  return request.post(`${endpoints.masterCity}`, payload)
}

export function updateCityAPI(payload, id) {
  return request.put(`${endpoints.masterCity}/${id}`, payload)
}

export function deleteCityAPI(id) {
  return request.delete(`${endpoints.masterCity}/${id}`)
}
