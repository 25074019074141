import { Button, Result, Spin } from 'antd'
import { useDispatch, useSelector } from 'react-redux'
import { Suspense, useEffect } from 'react'
import { Navigate, useRoutes, useNavigate } from 'react-router-dom'
import routes from './routes'
import { Loading } from '../../components'
import { userInfo, getUserProfile } from '../../pages/Login/login.slice'
import { getToken } from '../cookies'

const InitRouter = () => {
  const defaultPages = [
    { path: '/', element: <Navigate to={'/login'} /> },
    {
      path: '*',
      element: (
        <Result
          status="404"
          title="404"
          subTitle="Sorry, the page you visited does not exist."
          extra={<Button type="primary">Back Home</Button>}
        />
      ),
    },
  ]
  const Component = useRoutes([...routes, ...defaultPages])
  return (
    <Suspense
      fallback={
        <Loading.Pages>
          <Spin tip="loading page.." size="large" />
        </Loading.Pages>
      }
    >
      {Component}
    </Suspense>
  )
}

function InstanceRoutes() {
  const { isAuthenticated, validateUserLoader } = useSelector(userInfo)
  const dispatch = useDispatch()
  const navigate = useNavigate()

  useEffect(() => {
    if (isAuthenticated && !!getToken()) {
      dispatch(getUserProfile())
    }
    if (!isAuthenticated && !getToken()) {
      navigate('/login')
    }
    // eslint-disable-next-line
  }, [isAuthenticated])

  if (validateUserLoader) {
    return (
      <Loading.Pages>
        <Spin tip="validate user..." size="large" />
      </Loading.Pages>
    )
  }

  return <InitRouter />
}

export default InstanceRoutes
