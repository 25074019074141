import Cookies from 'js-cookie'
import { GLOBAL_CONST } from '../constant'

export function getToken() {
  return Cookies.get(GLOBAL_CONST.TOKEN_KEY)
}

export function setToken(token, expires_in) {
  var exdate= new Date();
	var time = exdate.getTime();
	time += 3600 * 1000 * expires_in;
	exdate.setTime(time);
  return Cookies.set(GLOBAL_CONST.TOKEN_KEY, token, { expires: exdate})
}

export function removeToken() {
  return Cookies.remove(GLOBAL_CONST.TOKEN_KEY)
}
